<template>
  <div class="adminka">
    <div class="section">
      <h5>
        <router-link :to="{name:'mainpage'}">Система управления приказами</router-link>
        <a class="logout-button waves-effect waves-orange btn btn-large brown lighten-2 tooltipped" data-position="left"
           data-tooltip="Выйти" @click="logOut"><i class="material-icons">exit_to_app</i></a></h5>
    </div>
    <div class="card-panel grey lighten-5 z-depth-1 top-menu">
      <div class="row mobile-flex-wrap">
        <div class="col s12 m6 l5">
          <a class="waves-effect waves-orange btn btn-large brown lighten-2" @click="openAddForm"><i
              class="material-icons">playlist_add_circle</i><span>Добавить
            новый</span></a>
        </div>
        <div class="col s12 m6 l5">
          <a class="waves-effect waves-orange btn btn-large brown lighten-2" @click="$router.go(-1)"><i
              class="material-icons">arrow_back</i><span>Назад</span></a>
        </div>

      </div>
    </div>
    <transition name="slide-fade">
      <div v-if="showMessage" class="card-panel green lighten-5 z-depth-1 top-menu">
        <div class="row">
          {{ message }}
        </div>
      </div>
    </transition>
    <router-view></router-view>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import dateformat from "dateformat";
import eventHub from "@/eventHub";

export default {
  name: 'Adminka',
  data() {
    return {
      orders: [],
      showMessage: false,
      message: '',
    }
  },
  computed: {
    ...mapGetters('Config', ['getApiRoot']),
  },
  methods: {
    ...mapActions('User', ['userLogOut']),
    openAddForm() {
      this.$router.push({name: 'addorder'});
    },
    showMessageCard(mess, time = 2000) {
      let self = this;
      this.message = mess;
      this.showMessage = true;
      setTimeout(() => {
        self.showMessage = false;
      }, time);
    },
    onPostPrikaz(m) {
      switch (m) {
        case "OK":
          this.showAddForm = false;
          this.showMessageCard('Приказ успешно добавлен!');
          eventHub.$emit('reload-prikazy');
          this.$router.push({name: 'orderslist', query:{category:eventHub.lastCategory}});
          break;
        case "cancel":
          this.showAddForm = false;
          break;
        default:
          this.showMessageCard('Ошибка! ' + m);
      }
    },
    formatDateTime(d) {
      if (d) {
        let date = new Date(d);
        return dateformat(date, 'yyyy-mm-dd HH-MM');
      } else return '';
    },
    doOpen(oid) {
      console.log(oid);
    },
    async logOut() {
      await this.userLogOut();
      this.$router.push({name: "mainpage"});
    }

  },
  mounted() {
    eventHub.$on('post-prikaz', this.onPostPrikaz);
  },
  components: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.top-menu {
  .btn {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 5px;
    i {
      width: 1.6rem;
    }
  }
}

.order {
  .row {
    display: flex;
    align-items: center;
  }
}

a {
  cursor: pointer;
}

.logout-button {
  margin-left: 20px;
}
@media (max-width: 800px) {
  .mobile-flex-wrap{
    flex-wrap: wrap;
  }
  .no-mobile{
    display: none;
  }
}

</style>
<style>
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}
</style>
